<template>
  <div>
    <headerTop></headerTop>
    <!-- 主体 -->
    <div class="resuly_content wrap">
      <div class="header">
        <div @click="GoIndex()" class="fl">
          <img src="../../assets/image/funuo.png" alt />
          孚诺药业
        </div>
        <div class="fr">
          <el-steps :active="state" align-center>
            <el-step title="选择商品" description></el-step>
            <el-step title="核对订单信息" description></el-step>
            <el-step title="提交订单" description></el-step>
          </el-steps>
        </div>
      </div>
      <div  v-if="statePay == 1" class="content">
          <div class="top">
              <img class="successImg" src="@/assets/image/ShoppingCart_Success@2x.png" alt="">
              <span>订单支付成功！</span>订单号:{{num}}
          </div>
          <p>你的订单已提交完成，我们将立即处理您的订单.......</p>
          <div @click="GoIndex()" class="go">返回首页</div>
      </div>
       <div  v-if="statePay == 0" class="content">
          <div class="top">
              <img class="successImg" src="@/assets/image/Settledin_fail_img@2x.png" alt="">
              <span style="color:red;">订单支付失败！</span>订单号：{{num}}
          </div>
          <div @click="GoIndex()" class="go">返回首页</div>
      </div>
	  <div  v-if="statePay == 3" class="content">
	      <div class="top">
	          <img class="successImg" src="@/assets/image/ShoppingCart_Success@2x.png" alt="">
	          <span>订单已提交！</span>订单号:{{num}}
	      </div>
	      <p>你的订单已提交完成，我们将立即处理您的订单.......</p>
	      <div @click="GoIndex()" class="go">返回首页</div>
	  </div>
    </div>
    <div class="bg1">
      <publicBottom></publicBottom>
    </div>
  </div>
</template>
<script>
import headerTop from "@/components/public/public_headerTop.vue";
import publicBottom from "@/components/public/public_bottom";

export default {
  components: {
    headerTop,
    publicBottom
  },
  data() {
    return {
      state: 3 ,// 步骤条
      statePay:0,
	  num:"",
    };
  },
  created() {
	this.getType();
  },
  methods: {
	  getType(){
		  if(this.$route.query.state!=undefined){
			  this.statePay = this.$route.query.state;
			     this.num = this.$route.query.num
		  }else if(this.$route.query.resuly!=undefined){
			  this.statePay = this.$route.query.resuly;
			     this.num = this.$route.query.orderId;
		  }
	  },
    GoIndex() {
      this.$router.replace({ path: "/" });
    }
  }
};
</script>
<style lang="less" scoped>
@import "../../assets/less/index";
</style>
